export const getFirstNWords = (inputString: string, n: number) => {
  // Split the input string into words using whitespace as the delimiter
  const words: string[] = inputString.split(/\s+/);

  // Slice the array to get the first N words
  const firstNWords: string[] = words.slice(0, n);

  const wordsCount = words.length;

  // Join the sliced words back into a string
  const result: string = firstNWords.join(' ');

  return {
    shortText: result,
    wordsCount,
  };
};
