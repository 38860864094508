import { Divider } from '@nextui-org/react';
import Image from 'next/image';

import Img1 from '../../../public/assets/images/HomeChannelreviewImg1.webp';
import Img2 from '../../../public/assets/images/HomeChannelreviewImg2.webp';
import Img4 from '../../../public/assets/images/HomeChannelreviewImg4.webp';
import Img3 from '../../../public/assets/images/HomeChannelreviewImgNew3-preview.webp';
import { useScreenWidth } from '../ScreenWidthProvider';

function ChannelReview() {
  const screenWidth = useScreenWidth();
  return (
    <div className="flex w-full items-center justify-center font-lora">
      <div
        className={`mt-[60px] flex w-[100%] flex-col items-center justify-center gap-[20px] pt-4 text-[black] md:mt-[60px] lg:mx-[50px] xl:mx-[148px] ${
          screenWidth < 750 ? 'px-[31px]' : ''
        }`}
      >
        {screenWidth > 750 ? (
          <div className="flex items-center justify-center font-work-sans text-[36px] font-normal leading-[32px]">
            <div>
              ART OF LIVING<span className="font-bold"> AS SEEN IN</span>
            </div>
          </div>
        ) : (
          <div className="flex w-full flex-col justify-start font-work-sans text-[28px] font-normal leading-[30px]">
            <div>ART OF LIVING</div>
            <div className="font-bold">AS SEEN IN</div>
          </div>
        )}

        {screenWidth < 750 ? (
          <div className="flex w-[100%] flex-col gap-[17px] pt-[35px]">
            <div className="flex">
              <div className="flex flex-[0.5]">
                {' '}
                <Image src={Img1} alt="" />
              </div>
              <div className="flex flex-[0.7] justify-start text-[18px] capitalize">
                {' '}
                <i>&quot;Life Changing&quot;</i>
              </div>
            </div>
            <Divider />
            <div className="flex">
              <div className="!mt-[8px] flex !h-[29px] flex-[0.5]">
                {' '}
                <Image src={Img2} alt="" />
              </div>
              <div className="flex flex-[0.7] justify-start text-[18px] capitalize">
                {' '}
                <i>
                  {' '}
                  &quot;May be the fastest growing spiritual practice on the
                  planet&quot;
                </i>
              </div>
            </div>
            <Divider />
            <div className="flex">
              <div className="-mt-[30px] flex flex-[0.5]">
                {' '}
                <Image src={Img3} alt="" />
              </div>
              <div className="flex flex-[0.7] justify-start text-[18px] capitalize">
                {' '}
                <i>
                  &quot;Shows promise in providing relief for depression&quot;
                </i>
              </div>
            </div>
            <Divider />
            <div className="flex">
              <div className="!mt-[4px] flex flex-[0.5]">
                {' '}
                <Image src={Img4} alt="" />
              </div>
              <div className="flex flex-[0.7] justify-start text-[18px] capitalize">
                {' '}
                <i>&quot;Like Fresh air to millions&quot;</i>
              </div>
            </div>
          </div>
        ) : (
          <div className="-mt-4 flex w-[100%] items-center justify-center !gap-[73px]">
            <div
              className={`mt-[68px] flex gap-[30px] ${
                screenWidth < 970
                  ? '!mt-16 !flex !flex-col !gap-[40px] !pl-[60px]'
                  : ''
              }`}
            >
              <div className="flex">
                <div
                  className={`flex w-[200px] flex-col ${
                    screenWidth < 970 ? 'w-[250px]' : ''
                  }`}
                >
                  <Image src={Img1} alt="" />
                  <div className="mt-[60px] text-[18px] capitalize">
                    <i>&quot;Life Changing&quot;</i>
                  </div>
                </div>
                <div
                  className={`flex w-[200px] flex-col ${
                    screenWidth < 970 ? 'w-[250px]' : ''
                  }`}
                >
                  <Image
                    src={Img2}
                    alt=""
                    className="!h-[29px]"
                    width={90}
                    height={29}
                  />
                  <div className="mt-[60px] text-[18px] capitalize">
                    <i>
                      &quot;May be the fastest growing spiritual practice on the
                      planet&quot;
                    </i>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className={`flex w-[200px] flex-col ${
                    screenWidth < 970 ? 'w-[250px]' : ''
                  }`}
                >
                  <Image src={Img3} alt="" className="-mt-[30px]" />
                  <div className=" mt-[20px] text-[18px] capitalize">
                    <i>
                      &quot;Shows promise in providing relief for
                      depression&quot;
                    </i>
                  </div>
                </div>
                <div
                  className={`flex w-[250px] flex-col ${
                    screenWidth < 970 ? 'w-[250px]' : ''
                  }`}
                >
                  <Image src={Img4} alt="" className="mt-[0px]" />
                  <div className="mt-[30px] flex text-[18px] capitalize">
                    <i>&quot;Like Fresh air to millions&quot;</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ChannelReview;
