import { useRouter } from 'next/router';

import { useScreenWidth } from '../ScreenWidthProvider';
import { CoursesCard } from './CoursesCard';

export const AolCourses = () => {
  const router = useRouter();
  const screenWidth = useScreenWidth();

  return (
    <div className="pt-7 font-work-sans text-black md:pt-0">
      <div className="flex flex-col gap-5 px-3 md:items-center">
        <div className="pb-8 pl-6 md:pb-16 md:text-center">
          <p className="w-[300px] text-3xl leading-[35px] md:w-full md:pb-4">
            ART OF LIVING <span className="font-bold">COURSES</span>
          </p>
          <p className="hidden md:block">
            Powerful breathing techniques, meditation, and wisdom that can
            change your life
          </p>
        </div>
      </div>
      <div className="flex flex-wrap justify-center gap-[10px] px-3">
        <CoursesCard
          title="Art of Living Part 1"
          description="Discover SKY Breath Meditation, an evidence-based technique that quickly reduces stress, balances emotions, and restores calm"
          backgroundImage={
            screenWidth > 768
              ? `${router.basePath}/assets/images/home-courses-1.webp`
              : `${router.basePath}/assets/images/sky-mobile.webp`
          }
          link="https://event.us.artofliving.org/us-en/artoflivingpart1/?utm_source=organic&utm_medium=home&utm_content=allcourses&course_id=811569"
          color="bg-gradient-to-r to-[#0F6FE2] from-[#56a4ff99]"
        />
        <CoursesCard
          title="Sahaj Samadhi Meditation"
          description="Learn how to let go of worries and enjoy deep rest with this practical, effective, and effortless meditation practice"
          backgroundImage={
            screenWidth > 768
              ? `${router.basePath}/assets/images/home-courses-2.webp`
              : `${router.basePath}/assets/images/sahaj-mobile.webp`
          }
          link="https://event.us.artofliving.org/us-en/sahajsamadhi/?utm_source=organic&utm_medium=home&utm_content=allcourses&course_id=999649"
          color="bg-gradient-to-r from-[#7464d699] to-[#7464D6]"
        />
        <CoursesCard
          title="Art of Living Part 2"
          description="Unplug from the world for a few days to give yourself a true vacation for mind, body, and spirit with a silent retreat"
          backgroundImage={
            screenWidth > 768
              ? `${router.basePath}/assets/images/home-courses-3.webp`
              : `${router.basePath}/assets/images/advance-course-mobile.webp`
          }
          link=" https://event.us.artofliving.org/us-en/artoflivingpart2/?utm_source=organic&utm_medium=home&utm_content=allcourses&course_id=811570"
          color="bg-gradient-to-r from-[#0098b599] to-[#0098B5]"
        />
        <CoursesCard
          title="Sri Sri Yoga"
          description="Improve your flexibility and nourish your spirit with this deeply relaxing style of yoga and its wealth of mind-body benefits"
          backgroundImage={
            screenWidth > 768
              ? `${router.basePath}/assets/images/home-courses-4.webp`
              : `${router.basePath}/assets/images/sri-sri-yoga-mobile.webp`
          }
          link="https://members.us.artofliving.org/us-en/lp/online-foundation-program?utm_source=organic&utm_medium=home&utm_content=allcourses&course_id=1004431"
          color="bg-gradient-to-r from-[#69a82f99] to-[#69A82F]"
        />
      </div>
    </div>
  );
};
