export const meditationData = [
  {
    id: 1,
    text: 'Meditation Overview',
    link: 'https://www.artofliving.org/us-en/meditation',
  },
  {
    id: 2,
    text: 'Benefits Of Meditation',
    link: 'https://www.artofliving.org/us-en/meditation/benefits/benefits-of-meditation',
  },
  {
    id: 3,
    text: 'Meditation For Beginners',
    link: 'https://www.artofliving.org/us-en/meditation/beginners-guide/meditation-for-beginners',
  },
  {
    id: 4,
    text: 'How To Meditate',
    link: 'https://www.artofliving.org/us-en/meditation/how-to/how-to-meditate-properly',
  },
  {
    id: 5,
    text: 'Chakras',
    link: 'https://www.artofliving.org/us-en/meditation/chakras/chakras-guide',
  },
  {
    id: 6,
    text: 'Guided Meditation',
    link: 'https://www.artofliving.org/us-en/meditation/beginners-guide/online-guided-meditation',
  },
  {
    id: 7,
    text: 'What Is Meditation?',
    link: 'https://www.artofliving.org/us-en/meditation/beginners-guide/what-is-meditation',
  },
  {
    id: 8,
    text: 'Yoga Nidra Meditation',
    link: 'https://www.artofliving.org/us-en/yoga/health-and-wellness/yoga-nidra-experience',
  },
  {
    id: 9,
    text: 'Body Scan Meditation',
    link: 'https://www.artofliving.org/us-en/meditation/how-to/body-scan-meditation',
  },
];

export const breathData = [
  {
    id: 2,
    text: 'Pranayama Breathwork',
    link: 'https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/breathing-techniques',
  },
  {
    id: 3,
    text: 'Deep Breathing Exercises',
    link: 'https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/deep-breathing-exercises',
  },
  {
    id: 4,
    text: 'Breathing Techniques',
    link: 'https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/breathing-techniques',
  },
  {
    id: 5,
    text: 'How To Increase Lung Capacity',
    link: 'https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/how-to-increase-lung-capacity',
  },
  {
    id: 6,
    text: 'Breathing Exercises For Anxiety / Anxiety Breathing',
    link: 'https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/breathing-exercises-for-anxiety',
  },
  {
    id: 7,
    text: 'Breathing Patterns',
    link: 'https://www.artofliving.org/us-en/breathwork/breathing-exercises-101/breathing-patterns',
  },
  {
    id: 8,
    text: 'Bhramari Breath',
    link: 'https://www.artofliving.org/us-en/breathwork/pranayama/bhramari-pranayama',
  },
  {
    id: 9,
    text: 'Alternate Nostril Breathing',
    link: 'https://www.artofliving.org/us-en/breathwork/pranayama/alternate-nostril-breathing',
  },
];

export const yogaData = [
  {
    id: 1,
    text: 'Sun Salutations',
    link: 'https://www.artofliving.org/us-en/yoga/poses/sun-salutation',
  },
  {
    id: 2,
    text: 'Mudras',
    link: 'https://www.artofliving.org/us-en/yoga/benefits/mudra-for-anxiety',
  },
  {
    id: 3,
    text: 'Yoga Poses',
    link: 'https://www.artofliving.org/us-en/yoga/poses/yoga-poses',
  },
  {
    id: 4,
    text: 'Benefits Of Yoga',
    link: 'https://www.artofliving.org/us-en/yoga/benefits/yoga-benefits',
  },
  {
    id: 5,
    text: 'Yoga For Beginners',
    link: 'https://www.artofliving.org/us-en/yoga/beginners/yoga-for-beginners',
  },
  {
    id: 6,
    text: 'Pigeon Pose',
    link: 'https://www.artofliving.org/us-en/yoga/poses/pigeon-pose',
  },
  {
    id: 7,
    text: 'Lotus Position',
    link: 'https://www.artofliving.org/us-en/yoga/poses/lotus-positition',
  },
  {
    id: 8,
    text: 'Shavasana',
    link: 'https://www.artofliving.org/us-en/yoga/poses/shavasana',
  },
];

export const sleepData = [
  {
    id: 1,
    text: 'Meditation For Sleep',
    link: 'https://www.artofliving.org/us-en/meditation-for-sleep-relaxation-techniques-for-body-and-mind',
  },
  {
    id: 2,
    text: 'Sleep Hygiene',
    link: 'https://www.artofliving.org/us-en/meditation/sleep/sleep-hygiene',
  },
  {
    id: 3,
    text: 'Guided Sleep Meditation',
    link: 'https://www.artofliving.org/us-en/meditation/sleep/guided-sleep-meditation',
  },
  {
    id: 4,
    text: 'How To Fall Back Asleep',
    link: 'https://www.artofliving.org/us-en/meditation/sleep/how-to-fall-back-asleep',
  },
  {
    id: 5,
    text: 'Meditation For Better Sleep',
    link: 'https://www.artofliving.org/us-en/meditation/sleep/meditation-for-better-sleep',
  },
  {
    id: 6,
    text: 'Sleep Tips',
    link: 'https://www.artofliving.org/us-en/meditation/sleep/sleep-tips',
  },
  {
    id: 7,
    text: 'Yoga Nidra',
    link: 'https://www.artofliving.org/us-en/yoga/health-and-wellness/yoga-nidra-experience',
  },
  {
    id: 8,
    text: 'Meditation Replace Sleep',
    link: 'https://www.artofliving.org/us-en/meditation/sleep/meditation-replace-sleep',
  },
];

export const stressData = [
  {
    id: 1,
    text: 'How To Relieve Stress',
    link: 'https://www.artofliving.org/us-en/stress/strategies-to-reduce-stress',
  },
  {
    id: 2,
    text: 'How To Calm Anxiety?',
    link: 'https://www.artofliving.org/us-en/blog/how-to-calm-anxiety',
  },
  {
    id: 3,
    text: "I'm So Lonely",
    link: 'https://www.artofliving.org/us-en/stress/relief/how-to-deal-with-loneliness',
  },
  {
    id: 4,
    text: 'Meditation For Anxiety',
    link: 'https://www.artofliving.org/us-en/stress/relief/meditation-for-anxiety',
  },
  {
    id: 5,
    text: 'How To Calm Down?',
    link: 'https://www.artofliving.org/us-en/stress/relief/how-to-calm-down',
  },
  {
    id: 6,
    text: 'How To Stop Overthinking?',
    link: 'https://www.artofliving.org/us-en/stress/relief/how-to-stop-overthinking',
  },
  {
    id: 7,
    text: 'Meditation For Stress',
    link: 'https://www.artofliving.org/us-en/stress/relief/meditation-for-stress',
  },
  {
    id: 8,
    text: 'How To Stop Worrying?',
    link: 'https://www.artofliving.org/us-en/stress/relief/how-to-stop-worrying',
  },
];

export const researchData = [
  {
    id: 1,
    text: 'SKY Research',
    link: 'https://www.artofliving.org/us-en/meditation/benefits/research-sudarshan-kriya',
  },
  {
    id: 2,
    text: 'Meditation Research',
    link: 'https://www.artofliving.org/us-en/blog/is-meditation-making-your-life-better-or-not-find-the-facts-now',
  },
  {
    id: 3,
    text: 'Meditation For Heart Health',
    link: 'https://www.artofliving.org/us-en/blog',
  },
  {
    id: 4,
    text: 'Meditation For A Healthy Brain',
    link: 'https://www.artofliving.org/us-en/blog/how-meditation-changes-your-brain',
  },
  {
    id: 5,
    text: 'Meditation For Immunity',
    link: 'https://www.artofliving.org/us-en/meditation/benefits/meditation-immunity',
  },
  {
    id: 6,
    text: 'Sudarshan Kriya (SKY Breath Meditation) Benefits',
    link: 'https://www.artofliving.org/us-en/meditation/benefits/sudarshan-kriya-benefits',
  },
  {
    id: 7,
    text: 'Yale Study',
    link: 'https://event.us.artofliving.org/us-en/research/',
  },
];
