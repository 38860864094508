import { Image } from '@nextui-org/react';

import BreathImg from '../../../public/assets/images/breathIcon.webp';
import MeditationImg from '../../../public/assets/images/meditationIcon.webp';
import ResearchImg from '../../../public/assets/images/researchIcon.webp';
import SleepImg from '../../../public/assets/images/sleepIcon.webp';
import StressImg from '../../../public/assets/images/stressIcon.webp';
import YogaImg from '../../../public/assets/images/yogaIcon.webp';
import {
  breathData,
  meditationData,
  researchData,
  sleepData,
  stressData,
  yogaData,
} from './data/FindSolutionsData';
import { Lists } from './Lists';

export const FindSolutionsDesktop = () => {
  const findSolutionsFooterStyles = {
    list: 'leading-8 gap-[6px]',
    header: 'text-lg font-semibold text-black',
    listItem: 'text-base text-black',
  };

  return (
    <div className="flex flex-col items-center justify-center gap-20 pb-[90px] font-work-sans text-black">
      <div className="flex flex-col items-center">
        <Image
          src="/assets/images/artOfLivingLogo.webp"
          alt="Meditation Icon"
          width={200}
          height={76}
          className="rounded-none"
        />
        <div className="mt-[67px] text-center">
          <p className="text-3xl">
            FIND <span className="font-bold">SOLUTIONS</span>
          </p>
          <p>
            Check out more resources to help you lower your stress and find
            lasting calm
          </p>
        </div>
      </div>
      <div className="grid w-full gap-16 px-[12%] md:grid-cols-2 lg:grid-cols-3 lg:px-[200px]">
        <Lists
          image={MeditationImg}
          header={{ id: 1, text: 'MEDITATION', link: '' }}
          data={meditationData}
          styles={findSolutionsFooterStyles}
        />
        <Lists
          image={BreathImg}
          header={{ id: 2, text: 'BREATHING', link: '' }}
          data={breathData}
          styles={findSolutionsFooterStyles}
        />
        <Lists
          image={YogaImg}
          header={{ id: 3, text: 'YOGA', link: '' }}
          data={yogaData}
          styles={findSolutionsFooterStyles}
        />
        <Lists
          image={SleepImg}
          header={{ id: 4, text: 'SLEEP', link: '' }}
          data={sleepData}
          styles={findSolutionsFooterStyles}
        />
        <Lists
          image={StressImg}
          header={{ id: 5, text: 'STRESS', link: '' }}
          data={stressData}
          styles={findSolutionsFooterStyles}
        />
        <Lists
          image={ResearchImg}
          header={{ id: 6, text: 'MEDITATION RESEARCH', link: '' }}
          data={researchData}
          styles={findSolutionsFooterStyles}
        />
      </div>
    </div>
  );
};
