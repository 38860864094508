import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

// import button styles
import { buttonStyles } from '@/styles/stylesByClasses/buttons';

function GetStarted() {
  const [showDialog, setShowDialog] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      setShowDialog(true);
    }, 5 * 1000);
  }, []);

  return (
    <div>
      <div
        onClick={() => {
          setShowDialog(!showDialog);
        }}
        className={`${buttonStyles.getStartedBlue__Solid} fixed right-[-50px] top-[calc(50vh-25px)] z-[100]`}
      >
        <p className="font-work-sans text-base font-semibold">Get Started</p>
      </div>

      {showDialog && (
        <div
          className="get-started-box fixed right-[80px] top-[calc(50vh-75px)] z-[999] flex size-auto cursor-pointer flex-col items-start justify-center gap-[16px] rounded-[10px] bg-white p-[18px] font-work-sans"
          style={{ background: 'white' }}
        >
          <p className="font-work-sans text-lg font-semibold">
            Find the right course for you
          </p>
          <p className="mt-[-8px] font-work-sans text-base text-gray-600">
            Answer a few quick questions...
          </p>
          <div className="mt-[10px] flex w-full flex-row items-center justify-center">
            <button
              onClick={() => {
                router.push(
                  'https://members.us.artofliving.org/us-en/course-finder/welcome'
                );
              }}
              className={`${buttonStyles.gradientBlue__Solid} text-semibold w-[130px]`}
            >
              Get started
            </button>
            <button
              type="button"
              onClick={() => {
                setShowDialog(false);
              }}
              className={`${buttonStyles.gradientBlue__Outline} ml-[5px]`}
            >
              Not now
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GetStarted;
