import { Button, Card, CardBody, CardFooter, Image } from '@nextui-org/react';
import Link from 'next/link';
import { FaArrowRight } from 'react-icons/fa';

import { useScreenWidth } from '../ScreenWidthProvider';

interface CoursesCardProps {
  title: string;
  description: string;
  backgroundImage: string;
  link: string;
  color?: string;
}
export const CoursesCard = ({
  title,
  description,
  backgroundImage,
  link,
  color,
}: CoursesCardProps) => {
  const screenWidth = useScreenWidth();

  return (
    <div className="w-full md:w-[570px]">
      <Card className="col-span-12 h-[550px] w-full rounded-none md:w-[570px]">
        <CardBody className="overflow-visible p-0">
          <Image
            removeWrapper
            alt="Card background"
            className="z-0 size-full rounded-none object-cover"
            src={backgroundImage}
          />
        </CardBody>
        <CardFooter className="p-0">
          <div className="absolute top-0 z-10 flex size-full">
            <div className="flex w-full flex-col items-center justify-end pb-[-10px]">
              <div className="flex w-full  flex-col items-center justify-center gap-2 px-6 pb-12 pt-20">
                <div className="flex w-full flex-col gap-5 md:items-center">
                  <div
                    className={`!-mt-[8px] mb-5 font-lora text-[32px] font-semibold leading-[36px] md:w-[280px] md:text-center md:text-[36px] ${
                      screenWidth < 361 ? '!w-[300px]' : ''
                    }`}
                  >
                    {title}
                  </div>
                  <div
                    className={`py-1 text-[16px] font-normal leading-[20px] text-[#000000] md:w-[420px] md:text-center ${
                      screenWidth < 500 ? '!w-[290px]' : ''
                    }`}
                  >
                    {description}
                  </div>
                </div>
                <Link href={link} className="w-full pt-4">
                  <Button
                    className={`mx-auto flex h-[62px] justify-center text-base font-[550] text-white md:w-[300px] md:border-[1px] md:border-[#000000] md:bg-transparent md:text-black ${
                      screenWidth < 769 && color
                    }`}
                    fullWidth
                  >
                    Learn More <FaArrowRight />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
