import './reviewStyles.scss';

import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import Slider from 'react-slick';

import { getFirstNWords } from '@/utils/Methods';

interface ReviewSlidePropsTypes {
  id: number;
  title: string;
  desc: string;
  avatar: string;
  quoteIcon: string;
  profileName: string;
  about: string;
}

export const ReviewSlide = (data: ReviewSlidePropsTypes) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const text = data?.desc;
  const maxWords = 20;
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const { shortText, wordsCount } = getFirstNWords(text, 20);

  return (
    <div className="mx-2 min-h-[380px] w-[320px] border-b-[8px] border-b-[#FF9C00] bg-white p-5 font-lora sm:min-h-[460px]  sm:w-[400px] sm:p-8 md:min-h-[365px] md:w-[500px]">
      <div className="flex justify-between">
        <p className="mr-1 font-lora text-[24px] font-semibold italic leading-[28px] sm:text-[36px] sm:leading-[36px] md:mr-0 md:font-normal  ">
          <i>{data.title}</i>
        </p>
        <div>
          <Image
            src={data.quoteIcon}
            alt="testimonal quotes image"
            height={76}
            width={50}
            className="mt-[2px] min-h-[50px] min-w-[76px] md:mt-0"
          />
        </div>
      </div>

      <p className=" mt-auto py-4 font-lora text-[18px] italic">
        {isReadMore || wordsCount <= maxWords ? text : shortText}{' '}
        {/* Conditionally render 'read more' only if the text.length is greater than maxLength */}
        {wordsCount > maxWords && (
          <button
            onClick={toggleReadMore}
            className="cursor-pointer hover:text-[#FF9C00]"
            type="button"
          >
            {isReadMore ? ' ...Read less' : '...Read more'}
          </button>
        )}
      </p>

      <div className="flex items-center gap-4 py-2  ">
        <Image
          src={data.avatar}
          alt={`testimonial of ${data.profileName}`}
          height={55}
          width={55}
          className="rounded-[50%]"
        />
        <div className="font-work-sans text-[14px] ">
          <p className="font-semibold text-[#000000]">{data.profileName}</p>
          <p className="font-normal text-[#6F6F6F]">{data.about}</p>
        </div>
      </div>
    </div>
  );
};
export default function Reviews() {
  const router = useRouter();
  const ReviewsData = [
    {
      id: 1,
      title: 'Come to Work in a Better Mindset',
      desc: "I was always interested in meditation and I had never meditated before. It was always something that was sort of elusive but Ithought that I maybe never be capable of it. I thought that maybe my mind moved too quickly or I wasn't focused enough. Directly after the course and since then... just this understanding that this moment in the now is to be appreciated and enjoyed... and that released a lot of stress for meWhen I come to work having already centered myself, it's way easier to plan... delegate tasks to other people, or work with other people. Now I come to work in a much better mindset, and that in turn translates into the quality of work and the way I deal with people at work. Just the way I process emotions, thoughts, and feelings is different from before.",
      avatar: `${router.basePath}/assets/images/testimonial-1.webp`,
      quoteIcon: `${router.basePath}/assets/icons/quoteIcon.png`,
      profileName: 'Dan J.',
      about: 'Chef Asheville, NC',
    },
    {
      id: 2,
      title: 'Great insights and practical tools',
      desc: "I joined the Art of Living Part 1 Course after an introductory meet up at the Art of Living Center. It felt like it could be a great program for me, and before I could change my mind, I signed up for it. If I had waited until later to join, I would have missed out on a truly life transforming experience. The program gave me great insights and practical tools to dealing with life's stressful situations. I met some really good friends that I still stay in contact with.",
      avatar: `${router.basePath}/assets/images/testimonial-2.webp`,
      quoteIcon: `${router.basePath}/assets/icons/quoteIcon.png`,
      profileName: 'Tiffany Guynes',
      about: 'Chef & private caterer, Austin, TX',
    },
    {
      id: 3,
      title: 'The best financial investment I have made in myself',
      desc: 'I was at the height of my career, with a high profile job and a wonderful family. But stress was taking a toll on my physical and mental health – and I was relying on antibiotics and pain medicine regularly. Practicing SKY Meditation has reduced my stress levels and improved my immune system dramatically. I haven’t needed medication for 4 years now! This course is the best financial investment I have made in myself',
      avatar: `${router.basePath}/assets/images/testimonial-4.webp`,
      quoteIcon: `${router.basePath}/assets/icons/quoteIcon.png`,
      profileName: 'Uma Vishwanath',
      about: 'Team lead in a high-tech company, Lexington, MA',
    },
    {
      id: 4,
      title: 'Much more confident and happier, despite the struggles',
      desc: 'Before the Art of Living Part 1 Course, I was on medication for panic and anxiety attacks. After the first day of the Art of Living Part 1 Course, I experienced a full night’s sleep, which was rare for me. The next morning was one of the happiest I had ever felt. I’ve been constant with my SKY meditation practice for the last two years - it makes me feel so alive. My friends and family tell me that I am much more confident and happier. Despite the struggles last year - my grandfather passed away, I lost my job and I went through a divorce - I still did not have a panic or anxiety attack. I feel grateful for this inner strength because I don’t know where I would be without it.',
      avatar: `${router.basePath}/assets/images/testimonial-3.webp`,
      quoteIcon: `${router.basePath}/assets/icons/quoteIcon.png`,
      profileName: 'Robert Delmont',
      about: 'Elementary music teacher, Boston, MA',
    },
  ];
  const settings = {
    className: 'slider variable-width  sm:min-h-[420px]',
    infinite: true,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    centerPadding: '60px',
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          centerPadding: '0px',
          centerMode: true,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className=" w-full bg-gradient-to-b from-[#FFFFFF]  via-[#FCE1A0] via-80% to-[#FFFFFF] py-[100px] sm:min-w-[360px] md:pt-0">
      <p className="w-[90%] px-8 font-work-sans text-[28px] font-bold leading-[35px] text-[#000000] md:m-auto md:mt-[112px] md:text-center md:text-[36px]">
        Transforming Lives through Ancient Meditation Techniques
      </p>
      <main className="mt-[40px] w-[100%] " id="tesimonial-slider">
        <Slider {...settings}>
          {ReviewsData.map((slideData: ReviewSlidePropsTypes) => (
            <ReviewSlide {...slideData} key={slideData.id} />
          ))}
        </Slider>
      </main>
    </div>
  );
}
