import { Accordion, AccordionItem, Image } from '@nextui-org/react';

import BreathImg from '../../../public/assets/images/breathIcon.webp';
import MeditationImg from '../../../public/assets/images/meditationIcon.webp';
import ResearchImg from '../../../public/assets/images/researchIcon.webp';
import SleepImg from '../../../public/assets/images/sleepIcon.webp';
import StressImg from '../../../public/assets/images/stressIcon.webp';
import YogaImg from '../../../public/assets/images/yogaIcon.webp';
import {
  breathData,
  meditationData,
  researchData,
  sleepData,
  stressData,
  yogaData,
} from './data/FindSolutionsData';
import { Lists } from './Lists';

export const FindSolutionsMobile = () => {
  const itemClasses = {
    base: 'py-0 w-full',
    title: 'font-medium text-medium focus:border-none hover:border-none',
    trigger:
      'flex justify-between p-4 focus:outline-none  hover:border-none border-none',
    indicator: 'text-medium',
    content: 'text-small px-2',
  };

  const accordionBodyListStyles = {
    list: 'leading-8 gap-[3px]',
    header: 'text-lg font-medium text-black',
    listItem: 'text-base text-black',
  };

  return (
    <div className="mb-3 pt-10 font-work-sans text-base">
      <div className="mb-7 flex flex-col items-center gap-5">
        <Image
          src="/assets/images/artOfLivingLogo.webp"
          alt="Meditation Icon"
          width={200}
          height={76}
          className="rounded-none"
        />
        <div className="text-center">
          <p className="text-3xl">
            FIND <span className="font-bold">SOLUTIONS</span>
          </p>
          <p className="w-[280px]">
            Check out more resources to help you lower your stress and find
            lasting calm
          </p>
        </div>
      </div>
      <Accordion
        className="flex w-full flex-col gap-1"
        itemClasses={itemClasses}
      >
        <AccordionItem
          className="font-medium text-[#595858]"
          key="meditation"
          aria-label="meditation"
          indicator={({ isOpen }) => (
            <div
              className={`text-lg font-medium text-[black] ${
                isOpen && 'rotate-45'
              }`}
            >
              +
            </div>
          )}
          title={
            <div className="flex items-center gap-8 font-[650]">
              <Image
                src={MeditationImg.src}
                alt="Meditation Icon"
                width={40}
                height={40}
              />
              MEDITATION
            </div>
          }
        >
          <div className="px-6">
            <Lists data={meditationData} styles={accordionBodyListStyles} />
          </div>
        </AccordionItem>
        <AccordionItem
          className="font-medium text-[#595858]"
          key="breath"
          aria-label="breath"
          indicator={({ isOpen }) => (
            <div
              className={`text-lg font-medium text-[black] ${
                isOpen && 'rotate-45'
              }`}
            >
              +
            </div>
          )}
          title={
            <div className="flex items-center gap-8 font-[650]">
              <Image
                src={BreathImg.src}
                alt="Breathing Icon"
                width={40}
                height={40}
              />
              BREATHING
            </div>
          }
        >
          <div className="px-6">
            <Lists data={breathData} styles={accordionBodyListStyles} />
          </div>
        </AccordionItem>
        <AccordionItem
          className="font-medium text-[#595858]"
          key="yoga"
          aria-label="yoga"
          indicator={({ isOpen }) => (
            <div
              className={`text-lg font-medium text-[black] ${
                isOpen && 'rotate-45'
              }`}
            >
              +
            </div>
          )}
          title={
            <div className="flex items-center gap-8 font-[650]">
              <Image src={YogaImg.src} alt="Yoga Icon" width={40} height={40} />
              YOGA
            </div>
          }
        >
          <div className="px-6">
            <Lists data={yogaData} styles={accordionBodyListStyles} />
          </div>
        </AccordionItem>
        <AccordionItem
          className="font-medium text-[#595858]"
          key="sleep"
          aria-label="sleep"
          indicator={({ isOpen }) => (
            <div
              className={`text-lg font-medium text-[black] ${
                isOpen && 'rotate-45'
              }`}
            >
              +
            </div>
          )}
          title={
            <div className="flex items-center gap-8 font-[650]">
              <Image
                src={SleepImg.src}
                alt="Sleep Icon"
                width={40}
                height={40}
              />
              SLEEP
            </div>
          }
        >
          <div className="px-6">
            <Lists data={sleepData} styles={accordionBodyListStyles} />
          </div>
        </AccordionItem>
        <AccordionItem
          className="font-medium text-[#595858]"
          key="stress"
          aria-label="stress"
          indicator={({ isOpen }) => (
            <div
              className={`text-lg font-medium text-[black] ${
                isOpen && 'rotate-45'
              }`}
            >
              +
            </div>
          )}
          title={
            <div className="flex items-center gap-8 font-[650]">
              <Image
                src={StressImg.src}
                alt="Stress Icon"
                width={40}
                height={40}
              />
              STRESS
            </div>
          }
        >
          <div className="px-6">
            <Lists data={stressData} styles={accordionBodyListStyles} />
          </div>
        </AccordionItem>
        <AccordionItem
          className="font-medium text-[#595858]"
          key="research"
          aria-label="research"
          indicator={({ isOpen }) => (
            <div
              className={`text-lg font-medium text-[black] ${
                isOpen && 'rotate-45'
              }`}
            >
              +
            </div>
          )}
          title={
            <div className="flex items-center gap-8 font-[650]">
              <Image
                src={ResearchImg.src}
                alt="Research Icon"
                width={40}
                height={40}
              />
              MEDITATION RESEARCH
            </div>
          }
        >
          <div className="px-6">
            <Lists data={researchData} styles={accordionBodyListStyles} />
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
