import { Button } from '@nextui-org/react';
import Image from 'next/image';
import Link from 'next/link';
import { FaArrowRight } from 'react-icons/fa';

import { useScreenWidth } from '@/components/ScreenWidthProvider';

import img1 from '../../../public/assets/images/HomepageSection2Img.webp';

function GurudevSection() {
  const screenWidth = useScreenWidth();
  return (
    <div className="flex w-full">
      <div
        className={`!my-[20px] flex w-[100%] p-4 lg:mx-[50px] xl:mx-[132px] ${
          screenWidth < 700
            ? 'flex flex-col items-center justify-center'
            : 'flex'
        }`}
      >
        <div
          className={`flex w-[100%] flex-col font-work-sans text-[black] sm:flex-[0.3] sm:p-[24px] md:mt-[60px] md:flex-[0.4] lg:flex-[0.65] lg:p-[0px] ${
            screenWidth < 700 ? 'px-[20px]' : 'flex'
          }`}
        >
          <div className="flex font-work-sans text-[32px] font-bold leading-[30px] tracking-[-1px] xs:flex-col sm:flex sm:flex-col md:flex md:flex-col md:gap-2 lg:flex lg:flex-row">
            Gurudev
            <div className="text-[32px] font-[400] leading-8">
              Sri Sri Ravi Shankar
            </div>
          </div>
          <div
            className={`mt-[22px] font-work-sans text-[18px] font-bold leading-[26px] text-black ${
              screenWidth < 700 ? 'font-work-sans !text-[16px]' : ''
            }`}
          >
            Be Inspired
          </div>
          <div
            className={`w-[100%] pr-4 font-work-sans text-base font-normal leading-[19px] md:w-[400px] lg:w-[500px]  ${
              screenWidth < 700
                ? 'mt-[0px] text-left font-work-sans leading-[22px]'
                : ''
            }`}
          >
            Founder of the Art of Living, Gurudev has transformed the lives of
            millions worldwide through meditation, yoga, and practical wisdom
          </div>
          {screenWidth < 700 ? (
            ''
          ) : (
            <Link href="https://www.artofliving.org/us-en/gurudev">
              <Button className="mt-[26px] h-[62px] w-[300px] border-[1px] border-[#000000] bg-transparent text-[14px] font-bold">
                Learn More <FaArrowRight />
              </Button>
            </Link>
          )}
        </div>
        <div className="-mt-2 flex flex-[0.5] items-center justify-center sm:flex-[0.7]">
          <Image src={img1} alt="" width={500} height={450} />
        </div>
        {screenWidth < 700 ? (
          <div className="flex w-full flex-col items-center justify-center px-5">
            <Link
              href="https://www.artofliving.org/us-en/gurudev"
              className="w-full"
            >
              <Button
                fullWidth
                className="mt-[26px] h-[62px] !items-center border-[1px] border-[#000000] bg-transparent text-[14px] font-bold"
              >
                Learn More <FaArrowRight />
              </Button>
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default GurudevSection;
