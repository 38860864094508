import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import Slider from 'react-slick';

import { useScreenWidth } from '../ScreenWidthProvider';

export const useSocialProjectsSlidesData = () => {
  const router = useRouter();
  const getslidesData = () => {
    return [
      {
        id: 1,
        img: `${router.basePath}/assets/images/sp-rehabiliation-imge.png`,
        mobileImg: `${router.basePath}/assets/images/sp-rehabiliation-mobile-image.png`,
        titlePart1: 'Prison',
        titlePart2: 'Rehabilitation',
        count: '800,000+',
        desc: 'prison inmates & staff have been transformed through these programs in over 65 countries ',
        color: 'text-[#2C6A8B]',
      },
      {
        id: 2,
        img: `${router.basePath}/assets/images/sp-plant-trees-image.png`,
        mobileImg: `${router.basePath}/assets/images/sp-plant-trees-mobile-image.png`,
        titlePart1: 'Tree',
        titlePart2: 'Plantations',
        count: '81 millions',
        desc: 'trees planted in 36 countries',
        color: 'text-[#336C00]',
      },
      {
        id: 3,
        img: `${router.basePath}/assets/images/sp-truma.png`,
        mobileImg: `${router.basePath}/assets/images/sp-truma-mobile.png`,
        titlePart1: 'Trauma Relief',
        titlePart2: 'for Veterans & Families',
        count: '8,000+',
        desc: 'veterans & their families have experienced this program',
        color: 'text-[#1554AF]',
      },
      {
        id: 4,
        img: `${router.basePath}/assets/images/sp-stress-free.png`,
        mobileImg: `${router.basePath}/assets/images/sp-stress-free-mobile.png`,
        titlePart1: 'Stress-free',
        titlePart2: 'College Campuses',
        count: '275,000',
        desc: 'students across 110+ colleges and universities have taken meditation and breathwork courses',
        color: 'text-[#A84000]',
      },
      {
        id: 5,
        img: `${router.basePath}/assets/images/sp-peace.png`,
        mobileImg: `${router.basePath}/assets/images/sp-peace-mobile.png`,
        titlePart1: 'Working for peace',
        titlePart2: 'In our cities',
        count: '91%',
        desc: 'Increase in the ability to remain calm in challenging situations. The program has brought together at-risk youth and law enforcement to build trust and peace ',
        color: 'text-[#0064B7]',
      },
      {
        id: 6,
        img: `${router.basePath}/assets/images/sp-empowering.png`,
        mobileImg: `${router.basePath}/assets/images/sp-empowering-mobile-image.png`,
        titlePart1: 'Empowering',
        titlePart2: 'Children K-12',
        count: '350,000',
        desc: 'Students across 225 schools have received the tools and mentorship needed to manage their minds and emotions.',
        color: 'text-[#5F2EB0]',
      },
    ];
  };

  return {
    getslidesData,
  };
};

export interface HeroSlidePropsTypes {
  id: number;
  img: string;
  mobileImg: string;
  titlePart1: string;
  titlePart2: string;
  count: string;
  desc: string;
  color?: string;
}

const Slide = (data: HeroSlidePropsTypes) => {
  const scereenWidth = useScreenWidth();
  return (
    <section className=" p-2 text-white ">
      {scereenWidth > 858 ? (
        <div className="h-[420px] w-[580px] ">
          <div className="relative size-full">
            <Image
              className="object-cover"
              fill
              src={`${data?.img}`}
              alt={`${data.titlePart1} ${data.titlePart2}`}
              quality={100}
            />
            <div className="absolute flex size-full flex-col items-start justify-between gap-4 pb-[45px] pl-[43px] pt-[32px] text-[#FFF9E4]">
              <div
                className={`w-[60%] font-lora text-[46px] ${data?.color}  leading-[47px]`}
              >
                {data.titlePart1}{' '}
                <span className="italic">{data.titlePart2}</span>
              </div>
              <div className="w-full ">
                <p className="mb-2 text-[36px] font-semibold">{data?.count}</p>
                <p className="w-[90%] text-[18px] leading-[20px]">
                  {data?.desc}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" h-[380px] w-[300px]">
          <div className="relative size-full">
            <Image
              className="object-cover"
              fill
              src={`${data?.img}`}
              alt={`${data.titlePart1} ${data.titlePart2}`}
              quality={100}
            />

            <div className=" absolute left-0 top-0 flex h-full flex-col items-start justify-between gap-4 pb-[24px] pl-[22px] pr-[18px] pt-[30px] text-[#FFF9E4]">
              <div
                className={` font-lora text-[30px] ${data?.color} leading-[35px]`}
              >
                {data.titlePart1}{' '}
                <span className="italic">{data.titlePart2}</span>
              </div>
              <div className="w-full ">
                <p className="mb-2 text-[24px] font-semibold">{data?.count}</p>
                <p className=" text-[14px] leading-[14px] ">{data?.desc}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const ServiceProjects = () => {
  const router = useRouter();
  const settings = {
    className: 'h-[420px] ',
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { getslidesData } = useSocialProjectsSlidesData();
  const slidesData = getslidesData();
  const scereenWidth = useScreenWidth();
  return (
    <section className="mt-[32px] w-full bg-gradient-to-b from-[#FFFEF9] via-[#FFE093]  via-75% to-[#FFFEF9] pt-[100px]  font-work-sans md:min-h-[860px] md:via-[#FFF7E4]">
      {scereenWidth > 768 ? (
        <Image
          src={`${router.basePath}/assets/images/semi-circle.png`}
          alt="semi circle icon"
          width={400} // Specify the width
          height={290} // Specify the height
          className="ml-auto mr-[60px] mt-[-100px]"
        />
      ) : (
        <Image
          src={`${router.basePath}/assets/images/semi-circle.png`}
          alt="semi circle icon"
          width={200} // Specify the width
          height={144} // Specify the height
          className="ml-auto mt-[-30px] md:mr-6"
        />
      )}
      <section className="-mt-40 pt-4 leading-8 md:mt-[-20px] lg:mt-[-140px]">
        <header className="pl-4">
          <div className="w-[330px] max-w-fit gap-3 px-4 text-[28px] font-normal uppercase text-[#000000] md:m-auto md:flex md:w-[700px] md:text-[36px]">
            <p>Art of Living</p>
            <p className="font-bold">Service Projects</p>
          </div>
          <p className="m-auto mt-4 px-4 text-[18px] font-normal leading-5 md:w-[630px] md:text-center">
            Empowering individuals and transforming communities through various
            social projects
          </p>
        </header>
        <main>
          {scereenWidth > 858 ? (
            <div className="m-auto mt-4 h-[500px] max-w-[1200px] ">
              <Slider {...settings}>
                {slidesData.map((slideData: HeroSlidePropsTypes) => (
                  <Slide {...slideData} key={slideData.id} />
                ))}
              </Slider>
            </div>
          ) : (
            <div className="mt-8 h-[500px] w-full">
              <Slider {...settings}>
                {slidesData.map((slideData: HeroSlidePropsTypes) => (
                  <Slide {...slideData} key={slideData.id} />
                ))}
              </Slider>
            </div>
          )}
        </main>
      </section>
    </section>
  );
};

export default ServiceProjects;
