export const buttonStyles = {
  gradientBlue__Solid:
    'rounded-full cursor-pointer text-white text-[14px] font-medium bg-gradient-to-r to-[#3D8BE8] from-[#89BEEC] h-[40px] ',
  gradientBlue__Outline:
    'rounded-full cursor-pointer text-[14px] font-medium h-[40px] w-[130px] border-[1px] border-[#3D8BE8] ',
  gradientYellowOchre__Solid:
    'cursor-pointer text-[20px] px-[32px] bg-gradient-to-r to-[#FF865B] from-[#FFD16F]',
  gradientYellowOchre__Outline: '',
  getStartedBlue__Solid:
    'cursor-pointer flex flex-col items-center justify-center font-work-sans bg-gradient-to-r to-[#3D8BE8] from-[#89BEEC] rounded-b-[16px] h-[50px] w-auto rotate-[90deg] px-[32px] py-[15px] text-[16px] text-white',
};
